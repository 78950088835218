
import { defineComponent, inject, PropType } from 'vue'
import { required } from '@vuelidate/validators'

import { useVuelidate } from '@vuelidate/core'
import { useToast } from '@/util/toast'
import { useErrorHandler } from '@/util/error-handler'

import { TransferRepositoryKey } from '@/data/injectables'
import { TransferForm, CreateTransferParams } from '@/data/transfer/types'

import NavBar from '@/components/navbar/NavBar.vue'
import BlockUI from '@/components/BlockUI.vue'
import FormField from '@/components/FormField.vue'
import SelectFish from '@/components/selects/SelectFish.vue'
import SelectPond from '@/components/selects/SelectPond.vue'
import { Pond } from '@/data/pond/types'
import { ScrollPosition } from '@/data/types'

export default defineComponent({
  components: { NavBar, FormField, SelectFish, SelectPond, BlockUI },
  props: {
    fishId: {
      type: Number as PropType<number>,
      default: null,
    },
    pondOriginId: {
      type: Number as PropType<number>,
      default: null,
    },
    quantity: {
      type: Number as PropType<number>,
      default: null,
    },
    scrollPos: {
      type: String as PropType<ScrollPosition>,
      default: 'top',
    },
  },
  setup: () => {
    const transferRepository = inject(TransferRepositoryKey)

    const toast = useToast()
    const { handleError } = useErrorHandler(toast)

    return {
      v$: useVuelidate(),
      transferRepository,
      handleError,
      ...toast,
    }
  },
  data() {
    return {
      creating: false,
      form: {
        datetime: new Date(),
        fish: this.fishId,
        quantity: this.quantity,
        pondOrigin: this.pondOriginId,
        description: 'Transferência entre açudes.',
      } as TransferForm,
      submitted: false,
    }
  },
  validations() {
    return {
      form: {
        datetime: {
          required,
        },
        fish: {
          required,
        },
        pondOrigin: {
          required,
        },
        pondDest: {
          required,
          diffThanOrigin: (val: number | Pond): boolean => {
            if (
              val &&
              typeof val !== 'number' &&
              typeof this.form.pondOrigin !== 'number'
            ) {
              return val.id !== this.form.pondOrigin?.id
            } else {
              return true
            }
          },
        },
        quantity: {
          required,
        },
      },
    }
  },
  computed: {
    busy(): boolean {
      return this.creating
    },
  },
  mounted() {
    if (this.scrollPos === 'bottom') {
      setTimeout(() => {
        window.scrollTo({
          left: 0,
          top: document.body.scrollHeight,
          behavior: 'smooth',
        })
      }, 500)
    }
  },
  methods: {
    async handleSave(): Promise<void> {
      this.submitted = true
      if (this.v$.$invalid) {
        return
      }
      await this.createTransfer()
    },
    async createTransfer(): Promise<void> {
      try {
        this.creating = true
        await this.transferRepository?.create(this.form as CreateTransferParams)
        this.showSuccess({
          title: 'Tudo certo',
          detail: 'Transferência criada com sucesso.',
        })
        this.$router.back()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.creating = false
      }
    },
  },
})
